import axios from 'axios'

import { formatError } from './utils'

export const fetch = async (url, genericError) => {
  try {
    const res = await axios.get(url)

    if (res.status >= 200 && res.status < 300) {
      return { error: false, data: res.data }
    } else {
      throw Error
    }
  } catch (e) {
    return formatError(e, genericError)
  }
}

export const post = async (url, data, genericError) => {
  try {
    const res = await axios.post(url, data)

    if (res.status >= 200 && res.status < 300) {
      return { error: false, data: res.data }
    } else {
      throw Error
    }
  } catch (e) {
    return formatError(e, genericError)
  }
}
